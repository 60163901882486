<template>
  <div class="content p-0 bg-secondary">
    <div class="login-wrapper">
      <div class="row m-0">
        <div
          class="col-lg-5 side-banner"
          style="
            background-image: url('/static/images/login-banner.jpg');
            background-position: center center;
            background-size: cover;
            height: 100vh;
          "
        >
          <div class="content px-5 text-center d-flex justify-content-center h-100">
            <div class="align-self-center">
              <img
                src="/static/images/login-logo.png"
                class="mb-4 w-50"
                style="max-width: 200px"
                alt="logo"
              />
              <h4 class="text-white">
                Trabajamos por el progreso de las Franquicias en Iberoamérica
              </h4>
              <router-link to="/" class="btn btn-secondary">Inicio</router-link>
            </div>
          </div>
        </div>
        <div class="col-lg-7 site-content d-flex align-items-center">
          <div class="content" style="width: inherit">
            <div class="row">
              <div class="col-lg-7 mx-auto">
                <div class="card bg-secondary shadow border-0">
                  <div v-if="!validatedCode" class="p-4">
                    <h6 class="login-title" style="text-align: center">
                      Ingresa el código de recuperación que enviamos a tu correo
                      electrónico
                    </h6>
                    <form action class="form" @submit.prevent="checkCode">
                      <v-text-field
                        v-model="code"
                        oninput="if(this.value.length > 6){this.value = this.value.slice(0,6);}"
                        :rules="[rules.required]"
                        type="number"
                        prepend-inner-icon="mdi-lock"
                        label="Código"
                        max="999999"
                        hint=""
                        counter
                        placeholder="000000"
                      ></v-text-field>
                      <v-row no-gutters style="display: flex; justify-content: center">
                        <button type="submit" class="btn btn-primary">
                          Validar código
                        </button>
                      </v-row>
                    </form>
                  </div>

                  <div v-if="validatedCode" class="p-4">
                    <h6
                      class="login-title"
                      style="text-transform: inherit; text-align: center"
                    >
                      Ingresa y confirma tu nueva contraseña
                    </h6>
                    <form action class="form" @submit.prevent="checkPassword">
                      <div class="col-12">
                        <v-text-field
                          v-model="password"
                          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                          :rules="[rules.required, rules.min]"
                          :type="show ? 'text' : 'password'"
                          prepend-inner-icon="mdi-lock"
                          label="Contraseña"
                          hint=""
                          counter
                          placeholder="Ingresa tu contraseña"
                          @click:append="show = !show"
                        ></v-text-field>
                      </div>
                      <div class="col-12">
                        <v-text-field
                          v-model="passwordConfirmation"
                          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                          :rules="[rules.required, rules.min]"
                          :type="show ? 'text' : 'password'"
                          prepend-inner-icon="mdi-lock"
                          label="Confirmación de contraseña"
                          hint=""
                          counter
                          placeholder="Ingresa tu contraseña"
                          @click:append="show = !show"
                        ></v-text-field>
                      </div>
                      <div class="col-md-12">
                        <div class="row">
                          <div style="margin: auto">
                            <button type="submit" class="btn btn-primary">
                              Restablecer contraseña
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'Recovery',
    data: () => ({
      email: '',
      code: '',
      show: false,
      password: '',
      passwordConfirmation: '',
      validatedCode: false,
      error: false,
      rules: {
        required: (value) => !!value || 'Requerido',
        min: (v) => v.length >= 6 || 'Escriba por lo menos 6 caracteres',
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'El formato de correo electrónico inválido'
        },
      },
    }),
    methods: {
      checkCode() {
        axios
          .get('password/find/' + this.code)
          .then((response) => {
            this.email = response.data.email
            this.validatedCode = true
          })
          .catch((error) => {
            this.$swal({
              title: 'Código inválido',
              text:
                'No hemos encontrado usuarios asociados al código de recuperación que ingresaste en nuestros registros',
              icon: 'error',
              showCancelButton: false,
              confirmButtonColor: '#e94e1b',
              showConfirmButton: true,
            })
            this.$swal.showValidationMessage(`Solicitud rechazada: ${error}`)
            setTimeout(() => location.reload, 5000)
            this.error = true
          })
      },
      checkPassword() {
        if (this.password == this.passwordConfirmation) {
          this.setPassword()
        } else {
          this.$swal({
            title: 'Falló la confirmación de la contraseña',
            text:
              'La confirmación de la contraseña no coincide. Por favor, intenta nuevamente',
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#e94e1b',
            showConfirmButton: true,
          })
        }
      },
      setPassword() {
        axios
          .post('password/reset', {
            email: this.email,
            password: this.password,
            password_confirmation: this.passwordConfirmation,
            token: this.code,
          })
          .then((response) => {
            this.$swal({
              title: 'Proceso completado',
              text: 'Haz restablecido tu contraseña exitosamente',
              icon: 'success',
              showCancelButton: false,
              confirmButtonColor: '#003d74',
              showConfirmButton: true,
            })
            this.$router.push('/login')
          })
          .catch((error) => {
            this.error = true
          })
      },
    },
  }
</script>
